import styles from "./Research.module.scss";

import therapieSessel from "../../images/fuellt_im_sessel_aus.jpg"

export function Research() {
	return (
		<>
			<h2>Forschung</h2>
			<p className="mt-3">
				Das Kreismodell wurde im Rahmen eigener Fortbildungen sowie im Fortbildungs-Rahmen kooperierender Institute im Kollegenkreis kollegial vorgestellt sowie diskutiert. Hier liegen erste qualitative Rückmeldungen vor.
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={therapieSessel} alt="Therapiesessel" />
			</div>
			<p className="mt-3">
				Das Kreismodell wurde im Rahmen des Innovationsfonds des Gemeinsamen Bundesausschuss (G-BA) im Oktober 2022 als Forschungsprojekt eingereicht (Projektleitung Prof. Dr. Ingo Jungclaussen, Fachhochschule des Mittelstands, Köln).
			</p>
			<p>
				Anhand einer Stichprobe von insgesamt 240 Therapeuten und Patienten soll im beantragten Forschungsprojekt untersucht werden, ob in der Experimentalgruppe im Vergleich zweier Kontrollgruppen ein signifikanter Unterschied hinsichtlich therapierelevanter Qualitäts-Faktoren (wie z.B. Symptomreduktion und Therapie-Zufriedenheit) nachweisbar ist. In einer explorativen abschließenden Befragung wird die Anschlussfähigkeit des Kreismodells an Supervision und Intervision untersucht, wodurch wichtige Impulse für die weitere Entwicklung neuer Formen der Qualitätssicherung gegeben werden können.
			</p>
			<p>
				Bisher im Projekt beantragte und beteiligte Kooperationspartner: IfP (UKE), PPT (Berlin), Deutsches Psychotherapeuten-Netzwerk.
			</p>
			<p>
				Interessierte Fachkolleginnen und Kollegen sowie weitere interessierte Kooperations- oder Bündnispartner nehmen bitte unter <a href="mailto:ingo.jungclaussen@fh-mittelstand.de">ingo.jungclaussen@fh-mittelstand.de</a> oder
				<a href="mailto:info@psy-dak.de">info@psy-dak.de</a> mit Prof. Dr. Jungclaussen Kontakt auf.
				Bei Interesse kann eine Demo-Version der Anwendung demonstriert werden.
			</p>
			<p>
				<b>In der 2. Jahreshälfte 2023 wird mit einer Entscheidung des G-BAs gerechnet.</b><br/>
				Info G-BA: <a href="https://www.g-ba.de/presse/pressemitteilungen-meldungen/1080/">https://www.g-ba.de/presse/pressemitteilungen-meldungen/1080/</a>
			</p>
			<p>
				Leitung: Prof. Dr. Ingo Jungclaussen. Kontakt: <a href="mailto:ingo.jungclaussen@fh-mittelstand.de">ingo.jungclaussen@fh-mittelstand.de</a> oder
				<a href="mailto:info@psy-dak.de">info@psy-dak.de</a>
			</p>
			<p>
				2019 wurde im Rahmen des Innovationsfonds des G-BAs ein anderes Forschungsprojekt (Leitung: Jungclaussen) zur Verbesserung und Modifikation des Gutachterverfahrens eingereicht: Das <a href="https://innovationsfonds.g-ba.de/downloads/media/164/Liste-gefoerderter-Projekte-VSF-FBK_2018-10-19_2018-11-23.pdf">"GKL-2" Projekt wurde zunächst bewilligt</a>, und musste dann aufgrund der gesetzlichen Änderungen (gesetzlich geschlossene Abschaffung des Gutachterverfahrens) 2020 rückabgewickelt werden.
			</p>
		</>
	);
}