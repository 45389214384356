import { Col, Row } from "react-bootstrap";

import homeImage from "../../images/home.jpeg";
import kreismodell from "../../images/kreismodell.jpg"
import fuelltImSesselAus from "../../images/fuellt_im_sessel_aus.jpg"
import musterbildKreismodell from "../../images/musterbild_kreismodell.jpg"
import besprechung from "../../images/besprechung.jpg"

import styles from "./Home.module.scss";

export function Home() {
	return (
		<div>
			<h2 className="text-center">Qualitäts-Monitoring durch Prozessfeedback in der ambulanten Psychotherapie</h2>
			<h4 className="text-center">Das Kreismodell</h4>
			<div className="text-center fs-7">Von Dr. phil. Ingo Jungclaussen (Psy-Dak – Fortbildungsinstitut für Psychodynamische Didaktik)</div>
			<Row className="mt-5 text-center">
				<Col className="mt-2" md={3}>Digital</Col>
				<Col className="mt-2" md={3}>Intersubjektives Feedback</Col>
				<Col className="mt-2" md={3}>Fallorientiert</Col>
				<Col className="mt-2" md={3}>Verfahrensübergreifend</Col>
			</Row>
			<div className="text-center mt-5 fs-5">
				Den aktuellen wissenschaftlichen Übersichtsartikel zum Kreismodell aus dem "Psychotherapie" Themenheft Qualitätssicherung finden Sie <b><a href="https://psy-dak.de/wp-content/uploads/2024/10/8489-PTH-24-2-INH-240919_Jungclaussen.pdf" rel="noopener" target="_blank">hier</a></b>
			</div>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-75" src={homeImage} alt="Bild Startseite" />
			</div>
			<p className="mt-3">
				Ziel des sog. „Kreismodells“ (Konzept: Ingo Jungclaussen, FHM/Psy-Dak) ist es, ein neuartiges digitales Instrument zu erforschen, das den psychotherapeutischen Prozess verbessern bzw. optimieren soll (Qualitäts-Monitoring durch Prozessfeedback).
			</p>
			<p>
				Das Kreismodell ermöglicht es, dass sowohl Therapeut*in als auch Patient*in dem gemeinsamen psychotherapeutischen Prozess entlang zentraler Qualitätskriterien als Selbstauskunft eine individuelle Rückmeldung geben. Die Abbildung 1 zeigt 4 polar angeordnete Achsen, mit insgesamt 8 Polen. Die Konstruktion der Achsen erfolgte theorie- bzw. forschungsbasiert, d. h. die Ergebnisse der Psychotherapie-Wirkungsforschung wurde gesichtet und umfassend berücksichtigt. Unter Berücksichtigung der Psychotherapie-Forschung lassen sich alle wissenschaftlich begründeten Wirkfaktoren verdichten („destillieren“) und auf 4 polar angeordnete Haupt-Dimensionen anordnen.
				Die vier polar angeordneten Achsen lauten: 1. Beziehung/Empathie vs. gedankliche abstrakte Meta-Ebene; 2. Ressourcenaktivierung vs. Problemaktualisierung; 3. Bewältigungsorientierung vs. motivationale Klärung; 4. Kontrolle/Struktur vs. Autonomie/Offenheit.
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={kreismodell} alt="Kreismodell" />
			</div>
			<div className="fs-7 mt-3 text-center">
				Abb. 1: Es werden die 4 polar angeordneten Achsen (mit 8 Polen) dargestellt, 
				die die Wirkfaktoren von Psychotherapie erfassen und abbilden.
			</div>
			<h2 className="mt-5">Verfahrensübergreifend:</h2>
			<p>
			Das Modell ist für alle 4 Psychotherapieverfahren (TP, AP, VT, ST) verfahrensübergreifend anwendbar, da es sich um übergeordnete Wirkfaktoren allgemeiner Art handelt („common factors“). 
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={fuelltImSesselAus} alt="Füllt im Sessel aus" />
			</div>
			<p className="mt-4">
				Mit einem digitalen Endgerät (Tablet) beantworten Patient*in und Therapeut*in zu ausgewählten Zeitpunkten in der laufenden Psychotherapie die entsprechend aus diesen Faktoren abgeleiteten Fragen (Items). Über das Antwortverhalten und das kreisförmige Netzdiagramm, werden die jeweiligen Sichtweisen über den Stand der Therapie bei Zeitpunkt x von Therapeut*in und Patient*in als Selbsteinschätzung grafisch abgebildet. Durch mehrere Messungen ergibt sich ein Prozessbild entlang der polar angeordneten vier Achsen (Wirkfaktoren). 
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={musterbildKreismodell} alt="Musterbild Kreismodell" />
			</div>
			<div className="fs-7 mt-3 text-center">
				Abb. 2: Zu sehen ist ein mögliches Antwortverhalten von Patient*in und Psychotherapeut*in (subjektive Selbsteinschätzung entlang der 4 polar angeordneten Achsen (Therapiefaktoren), mit insgesamt 8 Polen. Das Antwortmuster läßt als Selbsteinschätzung ein inkohärentes Erleben des psychotherapeutischen Prozesses erkennen: Während der Psychotherapeut (blau) alle 8 Prozessmerkmale signifikant höher einstuft als der Patient (rot), wird zudem auf der Achse „Lenken vs. Freiheit“ deutlich, dass der Therapeut subjektiv den Eindruck hat, den psychotherapeutischen Prozess stark zu strukturieren, während der Patient ein hohes Maß an Freiheit in der Therapie subjektiv erlebt. 
				(Kreismodell copyright Jungclaussen 2023)
			</div>
			<h2 className="mt-5">Prozess-Feedback:</h2>
			<p>
			Aus der gemeinsamen (!) intersubjektiven Reflexion können mögliche unausgesprochene Störungen oder Störungen in der Psychotherapie frühzeitig gemeinsam auf einer Meta-Ebene betrachtet und reflektiert werden.
			</p>
			<p>
			Aus den visuell dargestellten beiden Antwortmuster (Patient*in & Therapeut*in) erwachsen wichtige Impulse für die Prozesssteuerung, wodurch u. a. das therapeutische Vorgehen falls nötig modifiziert werden kann. Es besteht die Möglichkeit die digitale Abbildung mit einer Export-Funktion auf einem eigenen Endgerät zu speichern. Die Einhaltung aller Datenschutzbestimmungen wird sichergestellt.
			</p>
			<p>
			Zu Test- und Demozwecken wurde eine erste Anwendung erstellt, um die elektronische Beantwortung zu simulieren. Die Demo-Version läßt sich bereits im klinischen Kontext zu ersten Testzwecken anwenden. 
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={besprechung} alt="Besprechung" />
			</div>
			<p>
			Interessierte Fachkolleginnen und Kollegen sowie weitere interessierte Kooperations- oder Bündnispartner nehmen bitte unter <a href="mailto:info@psy-dak.de">info@psy-dak.de</a> mit Prof. Dr. Jungclaussen Kontakt auf.
			Bei Interesse kann eine Demo-Version der Anwendung demonstriert werden.
			</p>
			<p>
			Leitung: Prof. Dr. Ingo Jungclaussen. Kontakt: <a href="mailto:ingo.jungclaussen@fh-mittelstand.de">ingo.jungclaussen@fh-mittelstand.de</a> oder <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>
			</p>
		</div>
	);
}