import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export function AppStart() {
	const navigate = useNavigate();

	const start = () => {
		localStorage.clear();
		navigate("/app/patient");
	}

	return (
		<div>
			<h1 className="text-center my-3">Fragebogen</h1>
			<div className="text-center mt-5">
				<Button className="btn btn-primary" onClick={() => start()}>Start</Button>
			</div>
		</div>
	);
}