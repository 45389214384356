import { useEffect, useState } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { IQuestion, QuestionType, QuestionTypeLabels, QuestionTypes } from "../../data/questions";

export interface IQuestionaireProps {
	questions: { [key: QuestionType]: IQuestion[] }
	path: string;
	prevLink?: string;
	nextLink?: string;
}

export function Questionaire(props: IQuestionaireProps) {
	const params = useParams<"typeIndex">();
	const [values, setValues] = useState<{ [key: string]: number }>({});
	const [done, setDone] = useState(false);
	const typeIndex = Number(params.typeIndex) || 0;

	useEffect(() => {
		const currentType = QuestionTypes[typeIndex];
		const currentQuestions = props.questions[currentType];

		const currentValues = currentQuestions.reduce((prev: { [key: string]: number }, cur) => {
			prev[cur.key] = Number(localStorage.getItem(cur.key) || "0");
			return prev;
		}, {});

		setValues(currentValues);
		setDone(isDone(currentValues));
	}, [typeIndex, props, setValues, setDone]);

	const saveValue = (checked: boolean, key: string, value: number) => {
		if (checked) {
			localStorage.setItem(key, `${value}`);
			const newValues = Object.assign(values, { [key]: value })
			
			setValues(newValues);
			setDone(isDone(newValues));
		}
	}

	const getChecked = (key: string, score: number) => {
		const value = localStorage.getItem(key) || "0";
		return Number(value) === score;
	}

	const isDone = (values: { [key: string]: number }) => {
		return Object.keys(values).every(key => values[key] !== 0);
	}

	const getLabel = (score: number) => {
		switch (score) {
			case 1:
				return "1 (stimme gar nicht zu)";
			case 7:
				return "7 (stimme voll zu)";
			default:
				return `${score}`;
		}
	}

	return (
		<div>
			{QuestionTypes.map((type, idx) => {
				if (idx === typeIndex) {
					return (
						<Card key={type}>
							<Card.Header><b>Skala {idx + 1}:</b> {QuestionTypeLabels[type]}</Card.Header>
							<Card.Body>
								{props.questions[type].map((question: IQuestion) => {
									return (
										<div key={question.key} className="mt-2">
											<div>{question.question}</div>
											<Row className="mt-2">
												{[1, 2, 3, 4, 5, 6, 7].map(score => {
													return (
														<Col xl="auto" lg="4" xs="12" key={`${question.key}_${score}`}>
															<Form.Check
																label={getLabel(score)}
																name={question.key}
																defaultChecked={getChecked(question.key, score)}
																onChange={(ev) => saveValue(ev.target.checked, question.key, score)}
																type="radio"
																className="me-5" />
														</Col>
													);
												})}
											</Row>
										</div>
									);
								})}
								<Row className="mt-3">
									<Col>
										{typeIndex === 0 && props.prevLink &&
											<Link to={`${props.prevLink}/${QuestionTypes.length - 1}`} className="btn btn-primary float-start">Zurück</Link>
										}
										{typeIndex !== 0 &&
											<Link to={`${props.path}/${typeIndex - 1}`} className="btn btn-primary float-start">Zurück</Link>
										}
										{done && typeIndex === QuestionTypes.length - 1 && props.nextLink &&
											<Link to={props.nextLink} className="btn btn-primary float-end">Weiter</Link>
										}
										{done && typeIndex !== QuestionTypes.length - 1 &&
											<Link to={`${props.path}/${typeIndex + 1}`} className="btn btn-primary float-end">Weiter</Link>
										}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					);
				} else {
					return (null)
				}
			})}
		</div>
	);
}