import { Questions } from "../../data/questions";
import { Questionaire } from "../questionaire/Questionaire";

export function AppPatient() {
	return (
		<div>
			<h1 className="text-center my-3">Patient</h1>
			<Questionaire questions={Questions.patient} path="/app/patient" nextLink="/app/postPatient" />
		</div>
	);
}