import styles from "./Contact.module.scss"

import psychologie from "../../images/psychologie.jpeg"

export function Contact() {
	return (
		<>
			<h2>Kontakt / Impressum</h2>
			<p className="mt-3">
				Interessierte Fachkolleginnen und Kollegen sowie interessierte Kooperations- oder Bündnispartner nehmen unter <a href="mailto:info@psy-dak.de">info@psy-dak.de</a> mit Prof. Dr. Jungclaussen Kontakt auf.
				Bei Interesse kann eine Demo-Version der Anwendung demonstriert werden.
			</p>
			<address>
				Das Kreismodell<br/>
				Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen<br/>
				Psy-Dak- Institut für Psychodynamische Didaktik<br/>
				Erikastr. 18<br/>
				40627 Düsseldorf<br/>
				E-Mail: <a href="info@psy-dak.de">info@psy-dak.de</a><br/>
			</address>
			<p>
			Web:
				<ul>
					<li><a href="www.psy-dak.de">Psy-Dak</a></li>
					<li><a href="https://bit.ly/3gP0TIN">Fachhochschule des Mittelstands (FHM)</a></li>
				</ul>
			</p>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={psychologie} alt="Psychologie" />
			</div>
		</>
	);
}