import { Link } from "react-router-dom";

export function AppPostPatient() {
	return (
		<div>
			<h1 className="text-center my-3">Patient</h1>
			<div className="text-center mt-5">
				Vielen Dank für die Beantwortung der Fragen. Jetzt starten die Fragen für Ihren Therapeuten.
			</div>
			<div className="text-center mt-5">
				<Link className="btn btn-primary" to="/app/therapist">Weiter</Link>
			</div>
		</div>
	);
}