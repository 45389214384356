export interface IQuestions {
	patient: {
		[key: QuestionType]: IQuestion[]
	};
	therapist: {
		[key: QuestionType]: IQuestion[]
	};
}

export interface IQuestion {
	key: string;
	question: string;
}

export const QuestionTypes = ["lead", "coping", "relationship", "resources", "freedom", "motivation", "meta", "problem"];
export type QuestionType = typeof QuestionTypes[number];

export const QuestionTypeLabels: { [key: QuestionType]: string } = {
	lead: "Lenken, Strukturieren, Ziel, Adhärenz",
	coping: "Bewältigungs-Orientierung", 
	relationship: "Therapeutische Beziehung (Emotion)",
	resources: "Ressourcenaktivierung",
	freedom: "Freiheit, Flexibilität und Kongruenz",
	motivation: "Motivationale Klärung",
	meta: "Meta-Ebene (Kognition)",
	problem: "Problemaktualisierung"
}

export const Questions: IQuestions = {
	patient: {
		lead: [
			{
				key: "patient_lead_1",
				question: "Ich habe den Eindruck, dass mein Therapeut in den Gesprächen ein bestimmtes therapeutisches Konzept mit entsprechenden Zielen vor Augen hat."
			},
			{
				key: "patient_lead_2",
				question: "Mein Therapeut lenkt den Prozess und achtet auf einen roten Faden in unseren Gesprächen."
			}
		],
		coping: [
			{
				key: "patient_coping_1",
				question: "Mit Hilfe der Therapie habe ich das Gefühl, meinen konkreten Problemen außerhalb der Therapie besser gewachsen zu sein."
			},
			{
				key: "patient_coping_2",
				question: "Durch die Therapie kann ich meine Gefühle und Probleme besser bewältigen."
			}
		],
		relationship: [
			{
				key: "patient_relationship_1",
				question: "Die Atmosphäre in der therapeutischen Beziehung erlebe ich verständnisvoll und haltgebend."
			},
			{
				key: "patient_relationship_2",
				question: "Mein Therapeut kann sich gut in meine innere Welt einfühlen."
			}
		],
		resources: [
			{
				key: "patient_resources_1",
				question: "Die Therapie hilft mir dabei, meine Stärken und Fähigkeiten zu erkennen und zu nutzen."
			},
			{
				key: "patient_resources_2",
				question: "Die Therapie aktiviert in mir meine Potenziale, so dass dies meine Gesundheit fördert."
			}
		],
		freedom: [
			{
				key: "patient_freedom_1",
				question: "Mein Therapeut ist in seinem Vorgehen flexibel und lässt mir Freiheiten, dass ich mich und meine Themen zum Ausdruck bringen kann."
			},
			{
				key: "patient_freedom_2",
				question: "Ich erlebe meinen Therapeuten als Person authentisch."
			}
		],
		motivation: [
			{
				key: "patient_motivation_1",
				question: "Mit Hilfe der Therapie gewinne ich ein Verständnis für die Hintergründe meiner Probleme."
			},
			{
				key: "patient_motivation_2",
				question: "Durch die Therapie verstehe ich besser, was ich eigentlich will und mich innerlich antreibt."
			}
		],
		meta: [
			{
				key: "patient_meta_1",
				question: "Durch die Gedanken meines Therapeuten kann ich meine Probleme in neuen Zusammenhängen sehen."
			},
			{
				key: "patient_meta_2",
				question: "Durch die Sichtweisen meines Therapeuten sehe ich mich und meine Probleme in einem neuen Licht."
			}
		],
		problem: [
			{
				key: "patient_problem_1",
				question: "In den therapeutischen Gesprächen bin ich gefühlsmäßig beteiligt."
			},
			{
				key: "patient_problem_2",
				question: "Ich merke, dass ich in der Therapie meine Probleme so erlebe, dass hierdurch auf heilsame Weise etwas in Gang kommt."
			}
		]
	},
	therapist: {
		lead: [
			{
				key: "therapist_lead_1",
				question: "Ich habe für meinen Patienten ein bestimmtes therapeutisches Konzept mit entsprechenden Zielen vor Augen."
			},
			{
				key: "therapist_lead_2",
				question: "Als Therapeut lenke ich den Prozess und achte auf einen roten Faden in unseren Gesprächen."
			}
		],
		coping: [
			{
				key: "therapist_coping_1",
				question: "Mit Hilfe der Therapie hat mein Patient das Gefühl, dass er seinen konkreten Problemen außerhalb der Therapie besser gewachsen ist."
			},
			{
				key: "therapist_coping_2",
				question: "Durch die Therapie kann der Patient seine Gefühle und Probleme besser bewältigen."
			}
		],
		relationship: [
			{
				key: "therapist_relationship_1",
				question: "Die Atmosphäre in der therapeutischen Beziehung erlebe ich verständnisvoll und haltgebend."
			},
			{
				key: "therapist_relationship_2",
				question: "Ich kann mich gut in die innere Welt meines Patienten einfühlen."
			}
		],
		resources: [
			{
				key: "therapist_resources_1",
				question: "Die Therapie hilft meinem Patienten dabei, seine Stärken und Fähigkeiten zu erkennen und zu nutzen."
			},
			{
				key: "therapist_resources_2",
				question: "Die Therapie aktiviert in meinem Patienten seine Potenziale, so dass dies seine Gesundheit fördert."
			}
		],
		freedom: [
			{
				key: "therapist_freedom_1",
				question: "Ich bin in meinem therapeutischen Vorgehen flexibel und lasse dem Patienten Freiheiten, dass er sich und seine Themen zum Ausdruck bringen kann."
			},
			{
				key: "therapist_freedom_2",
				question: "Ich erlebe mich im Kontakt mit dem Patienten authentisch."
			}
		],
		motivation: [
			{
				key: "therapist_motivation_1",
				question: "Mit Hilfe der Therapie gewinnt der Patient ein Verständnis für die Hintergründe seiner Probleme."
			},
			{
				key: "therapist_motivation_2",
				question: "Durch die Therapie versteht mein Patient besser, was er eigentlich will und ihn innerlich antreibt."
			}
		],
		meta: [
			{
				key: "therapist_meta_1",
				question: "Der Patient kann durch meine therapeutischen Gedanken seine Probleme in neuen Zusammenhängen sehen."
			},
			{
				key: "therapist_meta_2",
				question: "Durch meine therapeutischen Sichtweisen erscheinen meinem Patienten seine Person und seine Probleme in einem neuen Licht."
			}
		],
		problem: [
			{
				key: "therapist_problem_1",
				question: "In den therapeutischen Gesprächen ist mein Patient gefühlsmäßig beteiligt."
			},
			{
				key: "therapist_problem_2",
				question: "In der Therapie erlebt der Patient seine Probleme so, dass hierdurch auf heilsame Weise etwas für ihn in Gang kommt."
			}
		]
	}
};