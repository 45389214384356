import { Questions } from "../../data/questions";
import { Questionaire } from "../questionaire/Questionaire";

export function AppTherapist() {
	return (
		<div>
			<h1 className="text-center my-3">Therapeut</h1>
			<Questionaire questions={Questions.therapist} path="/app/therapist" prevLink="/app/postPatient" nextLink="/app/results" />
		</div>
	);
}