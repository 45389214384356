export function Background() {
	return (
		<>
			<h2>Aktueller Hintergrund</h2>
			<p className="mt-3">
				Die Frage nach den neuen Formen der Qualitätssicherung (QS) in der ambulanten Psychotherapie stellt besonders mit Blick auf die gesetzlichen Änderungen das zentrale gesundheitspolitische Zukunftsthema dar.
			</p>
			<p>
				Um welche gesetzlichen Änderungen handelt es sich?
			</p>
			<p>
				Mit dem "Gesetz zur Reform der Psychotherapeutenausbildung" (PsychTherAusbRefG) vom 15. November 2019 wurden zusätzlich zur Reform der Psychotherapie-Ausbildung verschiedene versorgungsrelevante Regelungen beschlossen. Hierzu zählt, dass der Gesetzgeber dem G-BA in § 136a Absatz 2a SGB V auferlegt hat, bis zum 31. Dezember 2022 neue Formen der Qualitätssicherung zu entwickeln. Ziel ist die Implementierung einer "datengestützten einrichtungsübergreifenden Qualitätssicherung" (DeQS) auch im Bereich der Psychotherapie.
				Mit diesem Gesetz soll zugleich das bisherige Antrags- und Gutachterverfahren in Verbindung mit der Einführung des neuen DeQS aufgehoben werden. Das neue Verfahren DeQS soll das alte Verfahren (Antrags- und Gutachterverfahren) komplett ersetzen. Derzeit gilt das Antrags- und Gutachterverfahren übergangsweise bis 2025 (<a href="https://www.kbv.de/html/1150_59243.php">https://www.kbv.de/html/1150_59243.php</a>, siehe auch § 92 (6a) SGBV).
			</p>
			<p>
				Der Umstand, dass von diesen gesetzlichen Änderungen ca. ein Viertel der ambulanten vertragsärztlichen Versorgung betroffen ist, unterstreicht die besondere Relevanz des Themas. Der teils kontrovers geführte QS-Diskurs unterstreicht die Notwendigkeit zur weiteren objektiven Forschung auf diesem Gebiet.
			</p>
			<p>
				QS-Maßnahmen lassen sich zwischen Struktur, Prozess- und Ergebnisqualität unterscheiden. Während die aktuelle o.g. Diskussion, um neue Formen der QS am Beispiel der DeQS-Richtlinie von Fragestellungen zur Ergebnisqualität dominiert wird, möchte das folgende Projekt hiervon abgegrenzt eine andersgeartete Fragestellung zum Schwerpunkt Qualitäts-Monitoring und Prozessqualität erforschen.
			</p>
			<p>
				Seit einigen Jahren befinden sich digitalisierte Verfahren des Prozess-Monitorings in der ambulanten Psychotherapie im erfolgreichen Einsatz und ihre Nutzung gilt inzwischen als Merkmal von "good practice".
			</p>
		</>
	);
}