import styles from "./Person.module.scss";

import jungclaussen from "../../images/jungclaussen.jpg"

export function Person() {
	return (
		<>
			<div className={`text-center mt-5 ${styles.imageContainer}`}>
				<img className="w-50" src={jungclaussen} alt="Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen" />
			</div>
			<h2 className="mt-5">Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen</h2>
			<div className="fs-6">(Psy-Dak - Institut für Psychodynamische Didaktik, www.psy-dak.de)</div>
			<p>
				Prof. Dr. Ingo Jungclaussen lehrt, forscht und publiziert seit ca. 15 Jahren im Bereich des Antrags- und Gutachterverfahrens, welches zum Themenbereich interner Qualitätssicherung (QS) in der ambulanten Psychotherapie gehört. Darüber hinaus befasst er sich schwerpunktmäßig mit Didaktik- und Konzeptentwicklungen in der Psychotherapie, woraus das vorliegende Konzept des Kreismodells entstand.
			</p>
			<p>
				Als Hochschul-Professor an der <a href="https://bit.ly/3gP0TIN">Fachhochschule des Mittelstands in Köln (Klinische Psychologie und Psychotherapie)</a> unterrichtet er alle 4 Richtlinien-Verfahren sowie die Wirksamkeits-Forschung in der Psychotherapie.
			</p>
			<p>
				Ferner befasst Prof. Dr. Jungclaussen sich mit der Psychotherapiereform in Form einer fachwissenschaftlichen <a href="https://psy-dak.de/ueber-mich/veroeffentlichungen/">Interviewreihe</a> mit Vertreter*innen der Reform. Er war Veranstalter eines <a href="https://www.fh-mittelstand.de/news/artikel/psychologie-symposium-an-der-fachhochschule-des-mittelstands-fhm-2836">wissenschaftlichen Symposiums</a> zum Thema Reform der Psychotherapieausbildung an seiner Hochschule in Köln.
			</p>
			<p>
				Auf der Haupt-Webseite Psy-Dak können der <a href="https://psy-dak.de/ueber-mich/lebenslauf/">Lebenslauf</a> sowie die <a href="https://psy-dak.de/ueber-mich/veroeffentlichungen/">vollständige Publikationsliste</a> (auch mit Download-Möglichkeiten) eingesehen werden. Als Fortbildungsveranstalter ist er bundesweit mit verschiedenen psychotherapeutischen Fach- und Berufsverbänden in Deutschland, Österreich und der Schweiz vernetzt. Über seinen eigenen Newsletter erreicht er eine hohe Anzahl niedergelassener Ärztlicher und Psychologischer Richtlinien-Psychotherapeut*innen.
			</p>
			<p>
				Wissenschaftliche Tätigkeiten als Gastherausgeber einer psychotherapeutischen Zeitschrift zur Reform er Psychotherapieausbildung runden seine Aktivtäten auf diesem Gebiet ab (<a href="https://www.klett-cotta.de/ausgabe/PdP_-_Psychodynamische_Psychotherapie_Heft_02_Juni_2021/144144">Ausgabe 2/2021</a> Psychodynamische Psychotherapie, <a href="https://psy-dak.de/wp-content/uploads/2022/01/PDP_6112102_Editorial_-1.pdf">Editorial</a> hier)
			</p>
			<p>
				Forschung:<br/>
				2019 wurde im Rahmen des Innovationsfonds des G-BAs ein anderes Forschungsprojekt (Leitung: Jungclaussen) zur Verbesserung und Modifikation des Gutachterverfahrens eingereicht: Das <a href="https://innovationsfonds.g-ba.de/downloads/media/164/Liste-gefoerderter-Projekte-VSF-FBK_2018-10-19_2018-11-23.pdf">"GKL-2" Projekt wurde zunächst bewilligt</a>, und musste dann aufgrund der gesetzlichen Änderungen (gesetzlich geschlossene Abschaffung des Gutachterverfahrens) 2020 rückabgewickelt werden.
			</p>
			<p>
				Neue Forschung: Das Kreismodell wurde im Oktober 2022 beim G-BA im Rahmen des Innovationsfonds als Forschungsprojekt eingereicht. In der 2. Jahreshälfte 2023 wird mit einer Entscheidung gerechnet.
			</p>
			<p>
				(Die Konzept-Idee erwuchs frei aus der eigenen fachwissenschaftlichen Auseinandersetzung, und erfolgte ohne berufspolitische Interessen.)
			</p>
		</>
	);
}