import { ChartConfiguration } from "chart.js";
import { IQuestion, Questions, QuestionType, QuestionTypeLabels, QuestionTypes } from "../../data/questions";

const options = {
	elements: {
		line: {
			borderWidth: 3
		}
	},
	scales: {
		r: {
			min: 1,
			max: 7,
			pointLabels: {
				font: {
					size: 16
				},
			}
		}
	},
	plugins: {
		legend: {
			labels: {
				font: {
					size: 14
				}
			}
		}
	},
	maintainAspectRatio: false
};

function getData(questions: { [key: QuestionType]: IQuestion[] }) {
	let groupedData: { [key: QuestionType]: number[] } = {};

	for (let type of QuestionTypes) {
		groupedData[type] = [];

		for (let question of questions[type]) {
			const value = localStorage.getItem(question.key) || "1";
			groupedData[type].push(Number(value));
		}
	}

	return Object.keys(groupedData).map(key => {
		const sum = groupedData[key].reduce((prev, cur) => prev + cur, 0);
		return sum / groupedData[key].length;
	})
}

export function getCSVData() {
	const header = "Frage Patient;Antwort Patient;Frage Therapeut;Antwort Therapeut\n";
	let csv = header;

	for (let type of QuestionTypes) {
		const patientQuestions = Questions.patient[type];
		const therapistQuestions = Questions.therapist[type];

		// assert question array have same length
		if(patientQuestions.length === therapistQuestions.length) {
			for(let i = 0; i < patientQuestions.length; i++) {
				const pQuestion = patientQuestions[i];
				const tQuestion = therapistQuestions[i];

				csv += `${pQuestion.question};${localStorage.getItem(pQuestion.key) || "1"};${tQuestion.question};${localStorage.getItem(tQuestion.key) || "1"}\n`;
			}
		}
	}

	return csv;
}

export function getChartConfig(): ChartConfiguration<"radar"> {
	const data = {
		labels: QuestionTypes.map(type => QuestionTypeLabels[type]),
		datasets: [{
			label: 'Patient',
			data: getData(Questions.patient),
			fill: true,
			backgroundColor: 'rgba(255, 99, 132, 0.2)',
			borderColor: 'rgb(255, 99, 132)',
			pointBackgroundColor: 'rgb(255, 99, 132)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgb(255, 99, 132)'
		}, {
			label: 'Therapeut',
			data: getData(Questions.therapist),
			fill: true,
			backgroundColor: 'rgba(54, 162, 235, 0.2)',
			borderColor: 'rgb(54, 162, 235)',
			pointBackgroundColor: 'rgb(54, 162, 235)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgb(54, 162, 235)'
		}]
	};

	return {
		type: "radar",
		data: data,
		options: options
	};
}