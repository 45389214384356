import { useEffect } from "react";
import Chart from "chart.js/auto";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import cptable from "codepage";
import FileSaver from "file-saver";

import { getChartConfig, getCSVData } from "./DataService";
import { QuestionTypes } from "../../data/questions";

import styles from "./AppResults.module.scss";


export function AppResults() {
	useEffect(() => {
		const canvas = document.getElementById('results') as HTMLCanvasElement;

		new Chart<"radar">(canvas, getChartConfig());
	});

	const exportData = () => {
		const csv = getCSVData();
		const ansiCSV = cptable.utils.encode(1252, csv) as number[];
		const blob = new Blob([Uint8Array.from(ansiCSV).buffer], {type: "text/csv"});
		FileSaver.saveAs(blob, "export.csv");
	}

	return (
		<div>
			<h1 className="text-center mt-3">Ergebnisse</h1>
			<div className={`m-auto mt-3 ${styles.chart}`}>
				<canvas id="results"></canvas>
			</div>
			<Row className="my-5">
				<Col className="text-center">
					<Button onClick={exportData}>Export</Button>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<Link to={`/app/therapist/${QuestionTypes.length - 1}`} className="btn btn-primary float-start">Zurück</Link>
				</Col>
			</Row>
		</div>
	);
}