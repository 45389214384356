import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Home } from './components/home/Home';
import { AppPatient } from './components/appPatient/AppPatient';
import { AppResults } from './components/appResults/AppResults';
import { AppTherapist } from './components/appTherapist/AppTherapist';
import { AppStart } from './components/appStart/AppStart';
import { AppPostPatient } from './components/appPostPatient/AppPostPatient';
import { Person } from './components/person/Person';
import { Background } from './components/background/Background';
import { Research } from './components/research/Research';
import { Contact } from './components/contact/Contact';

function App() {
	return (
		<BrowserRouter>
			<Navbar bg="light" expand="lg" sticky="top">
				<Container>
					<Link to="/" className="navbar-brand">Kreismodell</Link>
					<Navbar.Toggle />
					<Navbar.Collapse>
						<Nav className="me-auto">
							<Nav.Link href="/">Startseite</Nav.Link>
							<NavDropdown title="Fragebogen">
								<NavDropdown.Item href="/app/start">Start</NavDropdown.Item>
								<NavDropdown.Item href="/app/patient">Patient</NavDropdown.Item>
								<NavDropdown.Item href="/app/therapist">Therapeut</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link href="/person">Zur Person</Nav.Link>
							<Nav.Link href="/background">Aktueller Hintergrund</Nav.Link>
							<Nav.Link href="/research">Forschung</Nav.Link>
							<Nav.Link href="/contact">Kontakt/Impressum</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Container className="mb-5">
				<div className="mt-3">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/app/start" element={<AppStart />} />
						<Route path="/app/patient">
							<Route path=":typeIndex" element={<AppPatient />} />
							<Route path="" element={<AppPatient />} />
						</Route>
						<Route path="/app/postPatient" element={<AppPostPatient />} />
						<Route path="/app/therapist">
							<Route path=":typeIndex" element={<AppTherapist />} />
							<Route path="" element={<AppTherapist />} />
						</Route>
						<Route path="/app/results" element={<AppResults />} />
						<Route path="/person" element={<Person />} />
						<Route path="/background" element={<Background />} />
						<Route path="/research" element={<Research />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</div>
			</Container>
		</BrowserRouter>
	);
}

export default App;
